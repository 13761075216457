import './style.scss'
import { Modal } from 'antd'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import * as homeApi from '@/api/home'
import Empty from '@/assets/empty.png'
import ModelBase from '@/assets/model-base.webp'
import ModelHigh from '@/assets/model-high.webp'
import { Arrow } from '@/assets/svg/arrow'
import PlanModal from '@/components/PlanModal'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import VoiceMarketBox from '../voice-market/components/VoiceMarketBox'
import CloneDrawer from './components/clone-drawer'
import CloneHighDrawer from './components/clone-high-drawer'
import VoiceBox from './components/VoiceBox'
import VoiceDetail from './components/VoiceDetail'

const Clone: FC = () => {
  const { userPackage } = UserStore
  const navigate = useNavigate()
  const [globalTts, setGlobalTts] = useState<any[]>([])
  const [selfTts, setSelfTts] = useState<any[]>([])
  const [bookTts, setBookTts] = useState<any[]>()
  const [open, setOpen] = useState(false)
  const [cloneHighOpen, setCloneHighOpen] = useState(false)
  const [modelModal, setModelModal] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [detailGroup, setDetailGroup] = useState<any>()
  const voiceDetailRef = useRef<any>()
  const [activeIndex, setActiveIndex] = useState(0)
  const [model, setModel] = useState(0)

  useEffect(() => {
    eventTracking('VoiceClonePage')
    ;(window as any).Buffer = Buffer
    getGlobalTts()
    getSelfTts()
    getBookTts()
    UserStore.updateUserPackage()
  }, [])

  const getSelfTts = async () => {
    const res = await homeApi.getSelfTtsGroups()
    setSelfTts(res?.list || [])
  }

  const getGlobalTts = async () => {
    const res = await homeApi.getGlobalTtsGroups()
    setGlobalTts(res?.list || [])
  }

  const getBookTts = async () => {
    const res = await homeApi.getBookmarkedCommunityVoice()
    setBookTts(res?.list || [])
  }

  const cloneAudio = () => {
    eventTracking('InstantCloneBasicClick')
    setModelModal(false)
    setModel(0)
    flushSync(() => {
      setOpen(true)
    })
  }

  const cloneFishAudio = () => {
    eventTracking('InstantCloneFishClick')
    setModelModal(false)
    setModel(1)
    flushSync(() => {
      setOpen(true)
    })
  }

  const upgradePlan = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>声音克隆-高保真是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        setPlanModalOpen(true)
      }
    })
  }

  const cloneHigAudio = async () => {
    eventTracking('InstantClonePremiumClick')
    setModelModal(false)
    if (userPackage && (userPackage.current_membership_level || 0) < 20) {
      return upgradePlan()
    }

    const res = await homeApi.getCloneableVoices()

    if (res?.list?.[0]?.last_demo_source_audio) {
      Modal.warning({
        title: '温馨提示',
        content: '您有一个克隆中的声音尚未完成克隆，点击前往克隆页面，去完成克隆。',
        okText: '去完成克隆',
        onOk: () => {
          setCloneHighOpen(true)
        }
      })
    } else {
      setCloneHighOpen(true)
    }
  }

  const onOk = () => {
    if (detailGroup?.id) {
      voiceDetailRef.current.getDetail()
    } else {
      getSelfTts()
    }
  }

  return (
    <div className="page-common page-clone">
      {detailGroup?.id ? (
        <VoiceDetail
          group={detailGroup}
          onBack={() => {
            setDetailGroup(undefined)
            getSelfTts()
            getBookTts()
          }}
          onAdd={() => {
            setModelModal(true)
          }}
          ref={voiceDetailRef}
          upgradePlan={() => setPlanModalOpen(true)}
        />
      ) : (
        <>
          <div className="common-title">
            <div className="text">
              个人声音{(selfTts.length || bookTts?.length) && bookTts ? `（${selfTts.length + bookTts.length}）` : null}
            </div>
          </div>
          <div className="clone-tabs">
            <div className={`tabs-item ${activeIndex === 0 ? 'actived' : ''}`} onClick={() => setActiveIndex(0)}>
              我的声音
            </div>
            <div className={`tabs-item ${activeIndex === 1 ? 'actived' : ''}`} onClick={() => setActiveIndex(1)}>
              我的收藏
            </div>
          </div>
          <div className="clone-wrapper">
            {activeIndex === 0 ? (
              <>
                <div
                  className="box-blank"
                  onClick={() => {
                    eventTracking('InstantCloneClick')
                    setModelModal(true)
                  }}
                >
                  <div className="left">
                    <div className="title">声音克隆</div>
                    <div className="desc">
                      <div>上传音频，快速克隆声音</div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="btn">
                      快速克隆
                      <Arrow />
                    </div>
                  </div>
                </div>

                <VoiceBox
                  onItemClick={(t) => setDetailGroup(t)}
                  onUpdate={getSelfTts}
                  list={selfTts}
                  upgradePlan={() => setPlanModalOpen(true)}
                />
              </>
            ) : (
              <>
                {bookTts?.length ? (
                  <VoiceMarketBox
                    onItemClick={(t) => setDetailGroup({ ...t, book: true })}
                    onDelete={getBookTts}
                    list={bookTts}
                  />
                ) : (
                  <div className="data-empty">
                    <img className="empty" src={Empty} />
                    <p>
                      暂无任何收藏，去
                      <span className="data-empty-link" onClick={() => navigate('/market/voice')}>
                        社区
                      </span>
                      逛逛
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="common-title">
            <div className="text">公共声音{globalTts.length ? `（${globalTts.length}）` : null}</div>
          </div>
          <div className="clone-wrapper">
            <VoiceBox onItemClick={(t) => setDetailGroup({ ...t, global: true })} list={globalTts} />
          </div>
        </>
      )}

      <CloneDrawer model={model} open={open} onCancel={() => setOpen(false)} onOk={onOk} group={detailGroup} />
      <CloneHighDrawer open={cloneHighOpen} onCancel={() => setCloneHighOpen(false)} onOk={onOk} group={detailGroup} />

      <Modal open={modelModal} title="模式选择" footer={null} onCancel={() => setModelModal(false)}>
        <div className="model-modal">
          <div className="model-item" onClick={cloneAudio}>
            <div className="left">
              <img src={ModelBase} />
            </div>
            <div className="right">
              <div className="title">声音克隆-基础版</div>
              <div className="desc">上传5~30秒音频，快速克隆个人声音，还原音色特点、说话风格</div>
            </div>
          </div>
          <div className="model-item" onClick={cloneFishAudio}>
            <div className="left">
              <img src={ModelBase} />
            </div>
            <div className="right">
              <div className="title">声音克隆-流畅版</div>
              <div className="desc">上传10~90秒音频，快速克隆声音，还原音色特点与说话风格，节奏流畅自然</div>
            </div>
            <div className="tag gray">推荐选择</div>
          </div>
          <div className="model-item" onClick={cloneHigAudio}>
            <div className="left">
              <img src={ModelHigh} />
            </div>
            <div className="right">
              <div className="title">声音克隆-高保真</div>
              <div className="desc">上传5~30秒音频，高度还原真人音色特点、说话风格、口音和声学环境</div>
            </div>
            <div className="tag">尊享专属</div>
          </div>
        </div>
      </Modal>

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            UserStore.updateUserPackage()
          }, 300)
        }
      />
    </div>
  )
}

export default memo(Clone)
