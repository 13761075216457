import './style.scss'
import { Dropdown, MenuProps } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cdnApi } from '@/api'
import * as Api from '@/api/account'
import IconActivity from '@/assets/activity.gif'
import Title from '@/assets/header-logo.png'
import { Agreement } from '@/assets/svg/agreement'
import { Arrow } from '@/assets/svg/arrow'
import { Loginout } from '@/assets/svg/loginout'
import { Privacy } from '@/assets/svg/privacy'
import { User } from '@/assets/svg/user'
import Person from '@/assets/user.png'
import ActivityModal from '@/components/ActivityModal'
import CreationShare from '@/components/CreationShare'
import PlanModal from '@/components/PlanModal'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface IProps {
  title?: string
}

const LayoutHeader: FC<IProps> = (props) => {
  const { title } = props
  const navigate = useNavigate()
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const { userPackage } = UserStore
  const [activityModalOpen, setActivityModalOpen] = useState(false)
  const [productList, setProductList] = useState<IProduct[]>([])
  const [showInteract, setShowInteract] = useState(false)

  useEffect(() => {
    getData()
    getInteractConfig()
  }, [])

  const getData = async () => {
    const res = await Api.getProductList()
    const list: IProduct[] = res?.list || []
    const activityProduct = list?.filter((p) => p.activity?.includes('activity'))
    setProductList(activityProduct || [])
    if (activityProduct?.length && !sessionStorage.getItem('activity') && !location.href.includes('setting')) {
      setActivityModalOpen(true)
    }
  }

  const getInteractConfig = async () => {
    const res = await cdnApi.get(`hiflys/interact/config.json`)
    if (res.open === true) {
      setShowInteract(true)
    }
  }

  const loginOut = () => {
    UserStore.loginOut()
  }

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <a href="javascript:void(0)" onClick={() => navigate('/setting')}>
          <User />
          个人中心
        </a>
      )
    },
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" className="header-menu-item" href="/privacy_agreement.html">
          <Privacy />
          隐私协议
        </a>
      )
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="/eula.html">
          <Agreement />
          用户协议
        </a>
      )
    },
    {
      key: '4',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={loginOut}>
          <Loginout />
          退出登录
        </a>
      )
    }
  ]

  return (
    <div className="layout-header">
      <div className="title">
        {title ? (
          <>
            <span className="icon" onClick={() => navigate(-1)}>
              <ArrowLeftOutlined />
            </span>
            <label>{title}</label>
          </>
        ) : (
          <img src={Title} onClick={() => navigate('/')} />
        )}
      </div>

      {/* {showInteract && (
        <div className="btn-interact" onClick={() => navigate('/digital')}>
          <img src={IconInteract} />
          <span>互动数字人尝鲜版</span>
        </div>
      )} */}

      <div className="right">
        {!!productList?.length && (
          <img
            className="icon-activity"
            src={IconActivity}
            onClick={() => {
              eventTracking('ActivityIconClick-618')
              setActivityModalOpen(true)
            }}
          />
        )}

        <CreationShare />

        <div
          className="btn"
          onClick={() => {
            eventTracking('CreateDisplayClick')
            navigate('/create-video')
          }}
        >
          创建视频 <Arrow />
        </div>
        {userPackage && (!userPackage?.membership_expire_time || userPackage.membership_expired) && (
          <div className="btn vip" onClick={() => setPlanModalOpen(true)}>
            开通VIP
          </div>
        )}

        <Dropdown
          className="header-menu"
          menu={{ items }}
          placement="bottomRight"
          arrow
          overlayClassName={'header-menu-item'}
        >
          <div className="user">
            <img src={Person} />
          </div>
        </Dropdown>
      </div>
      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            UserStore.updateUserPackage()
          }, 300)
        }
      />

      <ActivityModal
        open={activityModalOpen}
        onCancel={() => {
          setActivityModalOpen(false)
        }}
      />
    </div>
  )
}

export default memo(LayoutHeader)
