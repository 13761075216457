import './style.scss'
import { message, Modal, Popover, Radio } from 'antd'
import { FC, useEffect, useState } from 'react'
import ArrowRight from '@/assets/arrow_right.png'
import { UserStore } from '@/global-states'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { CreateVideoStore } from '../../create-video-state'

interface IProps {
  openPlan: () => void
}

const CreateVideoSetting: FC<IProps> = (props) => {
  const { highSetting, model } = CreateVideoStore
  const { userPackage } = UserStore
  const { openPlan } = props
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (userPackage && highSetting.mode === -1) {
      if (userPackage?.current_membership_level === 50) {
        CreateVideoStore.highSetting = { ...highSetting, mode: 1 }
      } else {
        CreateVideoStore.highSetting = { ...highSetting, mode: 0 }
      }
    }
  }, [userPackage, highSetting])

  const onDriveModelChange = (e: any) => {
    const value = e.target.value
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && value === 1) {
      return Modal.confirm({
        title: '当前会员等级不够，无法使用',
        content: <div>驱动模式-顺序驱动是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '升级会员',
        cancelText: '取消',
        onOk: () => {
          openPlan()
        }
      })
    }
    CreateVideoStore.highSetting = {
      ...highSetting,
      drive_mode: e.target.value
    }
  }

  const onAudioModelChange = (e: any) => {
    const value = e.target.value
    if (highSetting.mode === 1 && value) {
      return message.warning('暗黑模式下不支持仅音频输出')
    }
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && value) {
      return Modal.confirm({
        title: '当前会员等级不够，无法使用',
        content: <div>输出设置-仅音频是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '升级会员',
        cancelText: '取消',
        onOk: () => {
          openPlan()
        }
      })
    }
    CreateVideoStore.highSetting = {
      ...highSetting,
      only_generate_audio: value
    }
  }

  const onModeChange = (e: any) => {
    const value = e.target.value
    if (highSetting.only_generate_audio && value === 1) {
      return message.warning('暗黑模式下不支持仅音频输出')
    }
    if (userPackage && (userPackage.current_membership_level || 0) < 50 && value === 1) {
      return Modal.confirm({
        title: '黑金会员专属功能',
        content: <div>暗黑模式是黑金会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '开通黑金会员',
        cancelText: '取消',
        onOk: () => {
          openPlan()
        }
      })
    }
    CreateVideoStore.highSetting = {
      ...highSetting,
      mode: value
    }
  }

  const onModelVersionChange = (e: any) => {
    const value = e.target.value
    if (userPackage && (userPackage.current_membership_level || 0) < 10 && value === 1) {
      return Modal.confirm({
        title: '会员专属',
        content: <div>V1.5模型是会员专属功能，成为会员后可以使用V1.5模型来创作作品</div>,
        okText: '开通会员',
        cancelText: '取消',
        onOk: () => {
          openPlan()
        }
      })
    }
    CreateVideoStore.highSetting = {
      ...highSetting,
      model_version: value
    }
  }

  return (
    <div
      className="create-video-setting"
      style={{ width: open ? '320px' : 0, boxShadow: open ? '0 4px 12px 4px rgb(0 0 0 / 5%)' : 'none' }}
    >
      <div className="close-icon" onClick={() => setOpen(!open)}>
        <img src={ArrowRight} style={{ transform: open ? 'rotate(0)' : 'rotate(180deg)' }} />
      </div>

      <div style={{ opacity: open ? 1 : 0 }}>
        <div className="high-header">
          <label>高级选项</label>
        </div>
        <div className="high-setting">
          <div className="setting-item">
            <div className="title">
              驱动模式
              <Popover
                content={
                  <div className="common-popover">
                    <div>选择数字人视频在驱动时的模式：</div>
                    <ul>
                      <li>顺序驱动：使用数字人生成作品时会从你上传的原始视频第一帧开始顺序驱动</li>
                      <li>
                        随机模式：随机挑选片段来进行驱动
                        {`（生成的音频时长超过原视频长度时，按照顺序驱动，不会走随机模式）`}
                      </li>
                    </ul>
                  </div>
                }
                placement="right"
              >
                <ExclamationCircleOutlined />
              </Popover>
            </div>
            <Radio.Group onChange={onDriveModelChange} value={highSetting.drive_mode}>
              <Radio value={0}>随机模式</Radio>
              <Radio value={1}>顺序驱动</Radio>
            </Radio.Group>
          </div>

          {model === 'text' && (
            <div className="setting-item">
              <div className="title">
                输出设置
                <Popover
                  content={
                    <div className="common-popover">
                      <div>选择数字人视频在驱动时的模式：</div>
                      <ul>
                        <li>驱动视频：正常驱动数字人的视频作品</li>
                        <li>
                          仅音频：仅输出驱动后的音频文件，不做数字人驱动 （输出音频的积分消耗值与所选声音有关，请查看{' '}
                          <a
                            style={{ textDecoration: 'underline' }}
                            target="_blank"
                            href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                            rel="noreferrer"
                          >
                            积分说明
                          </a>
                          ）
                        </li>
                      </ul>
                    </div>
                  }
                  placement="right"
                >
                  <ExclamationCircleOutlined />
                </Popover>
              </div>
              <Radio.Group onChange={onAudioModelChange} value={highSetting.only_generate_audio}>
                <Radio value={false}>驱动视频</Radio>
                <Radio value={true}>仅音频</Radio>
              </Radio.Group>
            </div>
          )}

          <div className="setting-item">
            <div className="title">
              生成模式
              <Popover
                content={
                  <div className="common-popover">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="https://lingverse.feishu.cn/docx/VxnydIE4ao5NCyx7fnycqPX7npd#share-Evkidf4qYoA4AfxzoZJclzRkndh"
                          rel="noreferrer"
                          style={{ paddingLeft: 2, textDecoration: 'underline' }}
                        >
                          暗黑模式
                        </a>
                        ：创作不消耗积分
                      </li>
                      <li>积分模式：创作消耗积分</li>
                    </ul>
                  </div>
                }
                placement="right"
              >
                <ExclamationCircleOutlined />
              </Popover>
            </div>
            <Radio.Group onChange={onModeChange} value={highSetting.mode}>
              <Radio value={0}>积分模式</Radio>
              <Radio value={1}>暗黑模式</Radio>
            </Radio.Group>
          </div>

          <div className="setting-item">
            <div className="title">
              选择模型
              <Popover
                content={
                  <div className="common-popover">
                    <ul>
                      <li>V1.5模型数字人口齿更清晰，积分消耗规则与V1.0相同</li>
                    </ul>
                  </div>
                }
                placement="right"
              >
                <ExclamationCircleOutlined />
              </Popover>
            </div>
            <Radio.Group onChange={onModelVersionChange} value={highSetting.model_version}>
              <Radio value={0}>V1.0</Radio>
              <Radio value={1}>V1.5</Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateVideoSetting
