import './style.scss'
import { Button, Drawer, Input, message, Modal, notification, Popconfirm, Progress, Radio, Space } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { cmsApi } from '@/api'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import AudioDemos from '@/components/AudioDemos'
import PayPointModal from '@/components/PayPointModal'
import { eventTracking, urlSource } from '@/libs/util'
import { CloudUploadOutlined, ExclamationCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group?: any
  model?: number // 0-基础版 1-流畅版
}

const CloneDrawer: FC<IProps> = (props) => {
  const { open, onCancel, onOk, group, model } = props
  const [name, setName] = useState('')
  const [audioUrl, setAudioUrl] = useState<string>(undefined as any)
  const [audioInfo, setAudioInfo] = useState<any>({})
  const [percent, setPercent] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [declareChecked, setDeclareChecked] = useState(false)
  const cancelTokenSource = useRef<any>()
  const [clonePreflight, setClonePreflight] = useState<any>()
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (open) {
      getClonePreflight()
      setName('')
      resetUploadAudio()
      setDeclareChecked(false)
      showDeclare()
      setTitle('')
    }
  }, [open])

  const getClonePreflight = async () => {
    const res = await Api.clonePreflight({
      level: 10
    })
    setClonePreflight(res || {})
  }

  const beforeAudioUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
      if (['wav', 'mp3', 'm4a'].includes(type)) {
        const audioElement = document.createElement('audio')
        audioElement.src = URL.createObjectURL(file)
        audioElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', audioElement.duration)
          if (audioElement.duration < 5 || audioElement.duration > 180) {
            message.warning('音频时长要求为5秒～3分钟')
            resolve(false)
          }
          resolve(true)
        })
      } else {
        message.warning('请上传mp3、m4a、wav格式音频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
    if (!['wav', 'mp3', 'm4a'].includes(type)) {
      message.warning('请上传mp4、mov格式视频文件')
    }
  }

  const uploadFile = async (file: RcFile) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type, public_url } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type: 2
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()

      setPercent(0)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })
      setAudioInfo({
        title: segs[0],
        oss_key,
        public_url
      })
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const resetUploadAudio = () => {
    setPercent(-1)
    setAudioInfo({})
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
      resetUploadAudio()
    }
  }

  const selectDemoAudio = (v: any) => {
    setPercent(100)
    setAudioUrl(urlSource(v.url))
    setAudioInfo({
      oss_key: v.ossKey
    })
  }

  const showDeclare = () => {
    let index = 3

    const instance = Modal.confirm({
      width: 600,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于
              <label className="red">下架、警告、封禁账号</label>等处理方式。
            </li>
            <li>
              请勿使用我们的服务克隆或生成任何侵犯版权、违反道德伦理、或违反中华人民共和国法律法规的内容。我们生成的所有内容均带有详细日志，自动/人工复审，以及
              可溯源的隐形视频/音频水印，
              <label className="red">若发现您违反了相关规则，我们保留终止您的服务并上报公安机关等机构的权利。</label>
            </li>
            <li>
              更多信息请参阅
              <a target="_blank" href="/eula.html">
                用户协议
              </a>
              、
              <a target="_blank" href="/privacy_agreement.html">
                隐私协议
              </a>
              。
            </li>
          </ul>
        </>
      ),

      okText: (
        <div>
          我已知晓，同意<label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label>
        </div>
      ),
      cancelText: '取消',
      okButtonProps: {
        disabled: true
      },
      onOk: () => {
        setDeclareChecked(true)
      },
      onCancel: () => {
        setDeclareChecked(false)
        onCancel?.()
      }
    })

    const si = setInterval(() => {
      index = index - 1
      instance.update({
        okText: (
          <div>
            我已知晓，同意{index > 0 ? <label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label> : ''}
          </div>
        )
      })
      if (index < 1) {
        instance.update({
          okButtonProps: {
            disabled: false
          }
        })
        clearInterval(si)
      }
    }, 1000)
  }

  const onConfirm = async () => {
    if (!name?.trim() && !group?.id) {
      return message.warning('请输入声音名称')
    }

    if (group?.id && !title) {
      return message.warning('请输入声音风格')
    }

    if (!audioInfo.oss_key) {
      return message.warning('请上传参考音频')
    }

    if (!declareChecked) {
      return message.warning('请阅读并同意《使用者承诺须知》')
    }

    eventTracking(model === 1 ? 'InstantCloneFishConfirm' : 'InstantCloneBasicConfirm', {
      ossKey: audioInfo.oss_key
    })

    setLoading(true)
    try {
      const params: any = {
        audio_oss_key: [audioInfo.oss_key],
        level: 10,
        with_group: {
          new_group_title: name
        },
        tts_model: model
      }

      if (group?.id) {
        params.voice_name = title
        params.with_group = {
          group_id: group.id
        }
      }
      await homeApi.voiceClone(params)
      notification.success({
        message: '声音克隆中...',
        description: '快使用你的声音去创作视频吧！'
      })
      onCancel?.()
      onOk?.()
    } catch (error: any) {
      if (error.code === 1002) {
        setPayPointModalOpen(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Drawer
        className="create-drawer"
        title={model === 1 ? '声音克隆-流畅版' : '声音克隆-基础版'}
        width={720}
        onClose={onCancel}
        open={open}
        footer={
          <>
            <div className="declare">
              <Radio checked={declareChecked} onClick={() => setDeclareChecked(!declareChecked)}></Radio>
              我已阅读并同意
              <label className="link" onClick={showDeclare}>
                《使用者承诺须知》
              </label>
            </div>
            <Space>
              <div className="fee">
                <div className="score">
                  {/* <label
                    className={`light ${
                      clonePreflight?.limited_time_free || clonePreflight?.remained_free_quota > 0 ? 'through' : ''
                    }`}
                  >
                    消耗{clonePreflight?.credits_cost || 0}积分
                  </label> */}
                  {clonePreflight?.limited_time_free && <label className="light">限时免费</label>}
                </div>
                {/* <div
                  className={`num ${
                    !clonePreflight?.limited_time_free && clonePreflight?.remained_free_quota > 0 ? 'light' : 'gray'
                  }`}
                >
                  免费个数：{clonePreflight?.remained_free_quota || 0}
                </div> */}
              </div>
              <Button onClick={onCancel}>取消</Button>
              <Button disabled={!declareChecked} type="primary" onClick={onConfirm} loading={loading}>
                确定
              </Button>
            </Space>
          </>
        }
      >
        <div className="main">
          <div className="form-item">
            <div className="title">声音名称</div>
            <Input
              maxLength={20}
              placeholder="请输入声音名称"
              value={group?.title || name}
              onChange={(e) => setName(e.target.value)}
              disabled={group?.id}
            />
          </div>

          {group?.id && (
            <div className="form-item">
              <div className="title">风格名称</div>
              <Input
                maxLength={20}
                placeholder="请输入风格名称，如高兴、沮丧等"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          )}
          <div className="form-item">
            <div className="title">
              参考音频
              <Popconfirm
                title="温馨提示"
                description={
                  <div className="popconfirm-list" style={{ width: 620 }}>
                    <div>
                      （1）<strong>音频时长：</strong>建议音频时长限制在10s-30s；
                    </div>
                    <div>
                      （2）<strong>音频质量：</strong>
                    </div>
                    <ul>
                      <li>a. 只有一个在说话，避免音频中出现多个人的声音；</li>
                      <li>b. 保证低底噪。说话人说话越清晰，最终克隆效果越好，音频底噪会严重影响克隆效果；</li>
                      <li>c. 保持音量大小、语速稳定、注意断句、避免口腔噪音(如口水声)、杂音、混响等情况；</li>
                      <li>d. 音频中不要有背景音乐；</li>
                      <li>e. 音频中不要有桌椅响声、键盘鼠标敲击声、衣服摩擦声等人为噪声；</li>
                      <li>f. 音频中可以存在口误。口误时无需终止录音,可停顿1~2秒后,继续录制即可。</li>
                    </ul>
                    <div>
                      （3）<strong>音频内容：</strong>
                    </div>
                    <ul>
                      <li>
                        在录制音频前建议先确定好声音风格，在录音时尽量去贴近风格，避免录制的音频情绪韵律趋于平淡。
                        <span className="red">
                          如果希望克隆出的声音情绪饱满、韵律有起伏，请尽量上传表现力强的音频来克隆。
                        </span>
                      </li>
                    </ul>
                    <div>
                      （4）<strong>音频格式：</strong>
                    </div>
                    <ul>
                      <li>支持mp3、m4a、wav文件格式，音频文件大小不超过20M。</li>
                    </ul>
                  </div>
                }
                okText="我知道了"
                showCancel={false}
              >
                <ExclamationCircleFilled />
              </Popconfirm>
            </div>

            <div className="warning">
              <h4>音频要求</h4>
              <div className="desc">
                <div>
                  <label className="label">文件格式：</label>
                  <label>mp3、m4a、wav</label>
                </div>
                <div>
                  <label className="label">音频时长：</label>
                  <label>15秒～2分钟</label>
                </div>
              </div>
            </div>
            {percent === -1 && (
              <Dragger
                accept=".mp3,.m4a,.wav"
                showUploadList={false}
                beforeUpload={async (file) => {
                  const flag = (await beforeAudioUpload(file)) as any
                  if (flag) {
                    setAudioUrl(URL.createObjectURL(file))
                    uploadFile(file)
                  }
                  return flag
                }}
                onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
              >
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">请上传一段音频，作为制作数字人声音使用</p>
                <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
              </Dragger>
            )}

            {percent === -1 && open && <AudioDemos onSelect={selectDemoAudio} />}

            {percent > -1 && percent < 100 && (
              <div className="step-progress">
                <div className="step-progress-content">
                  <div className="percent">{percent}%</div>
                  <Progress percent={percent} showInfo={false} />
                  <div className="tips">音频上传中</div>
                </div>
                <div className="btns">
                  <Button onClick={cancelUpload}>取消上传</Button>
                </div>
              </div>
            )}

            {percent === 100 && (
              <div className="step-view">
                <div className="step-view-box">
                  <audio controls src={audioUrl} />
                </div>
                <div className="btns">
                  <div onClick={resetUploadAudio}>重新上传</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>

      <PayPointModal open={payPointModalOpen} onCancel={() => setPayPointModalOpen(false)} />
    </>
  )
}

export default memo(CloneDrawer)
