import './style.scss'
import { Button } from 'antd'
import { FC, memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Banner from '@/assets/banner-home.webp'
import Logo from '@/assets/login-logo.png'

const Mobile: FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.querySelector('html')?.setAttribute('id', 'mobile')
    return () => {
      document.querySelector('html')?.setAttribute('id', '')
    }
  }, [])

  const toMiniprogram = () => {
    location.href = 'weixin://dl/business/?appid=wx518485db92cb3213&path=pages/index/index'
  }

  const toHome = () => {
    sessionStorage.setItem('web', '1')
    navigate('/home')
  }

  const toMoble = () => {
    location.href = 'https://m.hifly.cc/'
  }

  return (
    <div className="page-h5">
      <div className="img">
        <img className="logo" src={Logo} />
        <img className="banner" src={Banner} />
      </div>
      <div className="btns">
        {/* <Button type="primary" onClick={toMiniprogram}>
          微信小程序中使用
        </Button> */}
        <Button onClick={toMoble}>前往访问手机版</Button>
        <Button onClick={toHome}>继续访问电脑版</Button>
      </div>
    </div>
  )
}

export default memo(Mobile)
