import './style.scss'
import { Button, Input, message, Modal, notification, Popover, Progress, Radio } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import Upload from 'antd/es/upload/Upload'
import axios from 'axios'
import { Buffer } from 'buffer'
import { match, pinyin } from 'pinyin-pro'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as uuid from 'uuid'
import wavConverter from 'wav-converter'
import { cdnApi, cmsApi } from '@/api'
import * as homeApi from '@/api/home'
import { PlayGray } from '@/assets/svg/play-gray'
import AudioDemos from '@/components/AudioDemos'
import PayPointModal from '@/components/PayPointModal'
import PlanModal from '@/components/PlanModal'
import VoiceSettingModal from '@/components/VoiceSettingModal'
import { UserStore } from '@/global-states'
import { defaultPreviewText, eventTracking, translateHtml, urlSource } from '@/libs/util'
import {
  ArrowLeftOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  LoadingOutlined,
  PauseOutlined,
  PlusOutlined
} from '@ant-design/icons'
import AvatarModal from './components/AvatarModal'
import RichText from './components/RichText'
import CreateVideoSetting from './components/Setting'
import VoiceModal from './components/VoiceModal'
import { CreateVideoStore } from './create-video-state'

const defaultText =
  '从当下这一刻起，拒绝内耗。只因命运不会偏袒任何人，却会眷顾一直朝着光亮前进的人。我相信，路虽远，行则可至；事虽难，做则可成。一切好事将至，祝大家如愿以偿。'

const CreateVideo: FC = () => {
  const { model, highSetting } = CreateVideoStore
  const navigate = useNavigate()
  const params: any = useParams()
  const [currentStep, setCurrentStep] = useState(0)
  const [audioFile, setAudioFile] = useState<RcFile>(undefined as any)
  const [audioUrl, setAudioUrl] = useState<string>(undefined as any)
  const [audioInfo, setAudioInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [currentTts, setCurrentTts] = useState<any>()
  const [currentAvatar, setCurrentAvatar] = useState<any>()
  const [textInputName, setTextInputName] = useState('未命名') // 文本驱动作品名称
  const [previewId, setPreviewId] = useState<any>(undefined)
  const prviewRef = useRef<any>()
  const [loading, setLoading] = useState(false)
  const audioRef = useRef<any>()
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [settingPreview, setSettingPreview] = useState<any>()
  const [sensitiveWords, setSensitiveWords] = useState<any[]>([])
  const [sensitiveWordsPinyin, setSensitiveWordsPinyin] = useState<any[]>([])
  const [voiceModalOpen, setVoiceModalOpen] = useState(false)
  const [playingId, setPlayingId] = useState()
  const [avatarModalOpen, setAvatarModalOpen] = useState(false)
  const [segmentIndex, setSegmentIndex] = useState(0)
  const [segments, setSegments] = useState<any[]>([])
  const segmentsCurrent = useRef<any>()
  const [audioSelectAvatar, setAudioSelectAvatar] = useState<any>({})
  const [open, setOpen] = useState(false)
  const lastRef = useRef<any>()

  const totalSeconds = useMemo(() => {
    const totalSeconds = segments?.reduce((pre, next) => {
      if (next.type === 1) {
        return pre + (next.duration || 0)
      } else {
        const s = translateHtml(next.words)?.length / 4.2
        return pre + s
      }
    }, 0)

    return Math.ceil(totalSeconds || 0)
  }, [segments])

  const showTime = useMemo(() => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const remainingSeconds = totalSeconds % 60

    if (totalSeconds >= 0 && totalSeconds <= 60) {
      return `${totalSeconds}秒`
    }
    if (totalSeconds > 60 && totalSeconds < 3600) {
      return `${minutes}分钟${remainingSeconds}秒`
    }
    if (totalSeconds >= 3600) {
      return `${hours}小时${minutes}分钟${remainingSeconds}秒`
    }
  }, [totalSeconds])

  const timeThanLimit = useMemo(() => {
    if ((highSetting.mode === 0 && totalSeconds > 3600) || (highSetting.mode === 1 && totalSeconds > 300)) {
      return true
    }
    return false
  }, [totalSeconds, highSetting.mode])

  useEffect(() => {
    segmentsCurrent.current = segments
  }, [segments])

  useEffect(() => {
    ;(window as any).Buffer = Buffer
    setTextInputName('未命名')
    restartUpload()
    initData()
    initSensitiveWords()
    getOpenConfig()

    return () => {
      prviewRef.current = undefined
      closeAudio()
      sessionStorage.removeItem('bookId')
    }
  }, [])

  useEffect(() => {
    if (segments?.length && lastRef.current) {
      lastRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [segments.length, lastRef])

  useEffect(() => {
    if (audioFile) {
      setCurrentStep(1)
      setAudioUrl(URL.createObjectURL(audioFile))
    } else {
      setAudioUrl(undefined as any)
    }
  }, [audioFile])

  useEffect(() => {
    prviewRef.current = previewId
  }, [previewId])

  const saveName = () => {
    if (!textInputName?.trim()) {
      setTextInputName('未命名')
    }
    setEdit(false)
  }

  const initData = async () => {
    const voiceId = localStorage.getItem('voiceId') || ''
    const bookId = sessionStorage.getItem('bookId')

    let speakers: any[] = []
    let avatars: any[] = []

    if (!bookId) {
      const [global, self, humans, globalHumans] = await Promise.all([
        getGlobalTts(),
        getSelfTts(),
        homeApi.getDigitalHumans(),
        homeApi.getGlobalDigitalHumans()
      ])
      speakers = [...self, ...global]?.filter((i) => i.status === 1) || []
      avatars = [...(humans?.list || []).filter((d: any) => d.status === 2), ...(globalHumans?.list || [])]
    } else {
      const [books, humans, globalHumans] = await Promise.all([
        homeApi.getCommunityVoiceList(+bookId),
        homeApi.getDigitalHumans(),
        homeApi.getGlobalDigitalHumans()
      ])
      speakers = books?.list || []
      avatars = [...(humans?.list || []).filter((d: any) => d.status === 2), ...(globalHumans?.list || [])]
    }

    const account = await UserStore.updateUserPackage()
    const level = account?.current_membership_level || 0
    // 过滤掉不可用的精品声音和高保真
    const canUseSpeakers = speakers.filter((s) => {
      if (s.is_premium && level < 10) {
        return false
      }
      if (s.level === 20 && level < 20) {
        return false
      }
      return true
    })
    const findVoice = canUseSpeakers.find((s) => s.id === +voiceId)
    let voice: any
    if (voiceId && findVoice) {
      voice = findVoice
    } else {
      voice = canUseSpeakers[0]
    }

    const findAvatar = avatars.find((s) => s.id === +params.avatarId)
    const renderAvatar = findAvatar ? findAvatar : avatars?.[0]
    setAudioSelectAvatar(renderAvatar)
    setSegments([
      {
        id: uuid.v4(),
        avatar: renderAvatar,
        voice,
        words: defaultText,
        type: 2,
        defaultText
      }
    ])
  }

  const getOpenConfig = async () => {
    const res = await cdnApi.get(`hiflys/create-video/config.json`)
    if (res.open === true) {
      setOpen(true)
    }
  }

  const getGlobalTts = async () => {
    const res = await homeApi.getGlobalTts()
    return res?.list || []
  }

  const getSelfTts = async () => {
    const res = await homeApi.getSelfTts()
    return res.list || []
  }

  const initSensitiveWords = async () => {
    const [content, pinyinContent] = await Promise.all([
      cdnApi.get(`hiflys/sensitive_words_lines.json?date=${Date.now()}`),
      cdnApi.get(`hiflys/sensitive_words_pinyin.json?date=${Date.now()}`)
    ])
    setSensitiveWords(content || [])
    setSensitiveWordsPinyin(pinyinContent || [])
  }

  const checkSensitive = () => {
    const errorWords: any[] = []
    try {
      segments?.forEach((s) => {
        const platWords = translateHtml(s.words).replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '')
        const pinyinWords = pinyin(platWords, { type: 'array', toneType: 'none' })
        const pinyinWordsString = pinyinWords?.join('')
        if (s.type === 2) {
          // 精确匹配
          sensitiveWords?.forEach((word) => {
            if (word?.length && !errorWords.includes(word) && platWords.includes(word)) {
              errorWords.push(word)
            }
          })

          // 拼音校验
          sensitiveWordsPinyin?.forEach((word) => {
            if (word?.length && pinyinWordsString.includes(word)) {
              const matchs = match(platWords, word, { continuous: true, precision: 'every' })
              if (matchs?.length) {
                const matchsString = platWords.substring(matchs[0], (matchs.at(-1) || 0) + 1)
                !errorWords.includes(matchsString) && errorWords.push(matchsString)
              }
            }
          })
        }
      })
    } catch (err) {
      console.log(err)
    }

    return errorWords
  }

  const uploadFile = async (
    file: RcFile,
    onUploadProgress?: (p: any) => void,
    onUploadSuccess?: (url: any) => void
  ) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type: 2
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      onUploadProgress?.(0)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          onUploadProgress?.(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      onUploadSuccess?.(oss_key)
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const beforeAudioUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
      if (['wav', 'mp3', 'm4a'].includes(type)) {
        const audioElement = document.createElement('audio')
        audioElement.src = URL.createObjectURL(file)
        audioElement.addEventListener('loadedmetadata', () => {
          const duration = audioElement.duration
          console.log('videoElement', duration)
          if (duration < 5 || duration > 1800) {
            message.warning('音频时长要求为5秒～30分钟')
            resolve(false)
          }
          resolve(duration)
        })
      } else {
        message.warning('请上传mp3、m4a、wav格式音频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file?.name?.split(/\./)?.slice(-1)?.[0]?.toLocaleLowerCase() as string
    if (!['wav', 'mp3', 'm4a'].includes(type)) {
      message.warning('请上传mp4、mov格式视频文件')
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setAudioFile(undefined as any)
    setAudioInfo(undefined as any)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const completeCreate = async () => {
    setLoading(true)
    const errorWords = checkSensitive()
    if (errorWords?.length) {
      setLoading(false)
      eventTracking('sensitiveWords', {
        sensitiveWord: errorWords.join('、')
      })
      return message.error(
        <div style={{ padding: '12px' }}>
          您提交的输入描述词包含敏感字符<label className="red">（{errorWords.join('、')}</label>），请修改后再提交
        </div>
      )
    }

    try {
      const res = await homeApi.authRotate({
        seed: uuid.v4(),
        replace: true
      })

      const checkRes = await UserStore.checkUserPackage()

      if (checkRes) return

      let postRes

      await eventTracking('submitCreation')

      if (model === 'text') {
        postRes = await homeApi.addCreations(res.server_time, {
          type: 0,
          title: textInputName,
          client_type: 1,
          drive_mode: highSetting.drive_mode,
          only_generate_audio: highSetting.only_generate_audio,
          mode: highSetting.mode,
          model_version: highSetting.model_version,
          segments: segments.map((s) => {
            if (s.type === 1) {
              return {
                type: s.type,
                digital_human_id: s.avatar.id,
                audio_oss_key: s.audio_oss_key
              }
            } else {
              return {
                type: s.type,
                digital_human_id: s.avatar.id,
                voice_name: s.voice.name,
                source_text: translateHtml(s.words)
              }
            }
          })
        })
      } else {
        postRes = await homeApi.addCreations(res.server_time, {
          type: 0,
          digital_human_id: audioSelectAvatar.id,
          title: textInputName,
          audio_oss_key: audioInfo.oss_key,
          client_type: 1,
          drive_mode: highSetting.drive_mode,
          mode: highSetting.mode,
          model_version: highSetting.model_version
        })
      }

      if (postRes.sensitive_words) {
        return Modal.error({
          title: '提交失败',
          content: (
            <div>
              您提交的内容中包含敏感内容（<span className="red">{postRes.sensitive_words.join('、')}</span>
              ），请修改后再次提交。
            </div>
          )
        })
      }

      if (model === 'text' && highSetting.only_generate_audio) {
        notification.success({
          message: '音频生成中',
          description: '音频生成中...，请耐心等待'
        })
      } else {
        notification.success({
          message: '视频生成中',
          description: '视频生成中...，请耐心等待！'
        })
      }

      toVideo()
    } catch (error: any) {
      if (error.code === 1002) {
        setPayPointModalOpen(true)
      }
      if (error.code === 1003 || error.code === 1004 || error.code === 1005) {
        setPlanModalOpen(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const submitForm = () => {
    if (model === 'text') {
      submitTextCheck()
    } else {
      submitAuduioCheck()
    }
  }

  const submitTextCheck = () => {
    if (segments.some((s) => !translateHtml(s.words) && s.type === 2)) {
      return message.warning('请输入台词')
    }

    if (segments.some((s) => s.words && s.type === 2) && totalSeconds < 2) {
      return message.warning('作品时长太短')
    }
    if (timeThanLimit) {
      if (highSetting.mode === 0) {
        return Modal.warning({
          title: '温馨提示',
          content: (
            <div>
              积分模式下，单个作品最大时长限制为<span className="red">3600秒</span>，当前作品已超出
            </div>
          ),
          okText: '我已知晓，前往修改'
        })
      } else {
        return Modal.warning({
          title: '温馨提示',

          content: (
            <div>
              暗黑模式下，单个作品最大时长限制为<span className="red">180秒</span>，当前作品已超出
            </div>
          ),
          okText: '我已知晓，前往修改'
        })
      }
    }
    completeCreate()
  }

  const submitAuduioCheck = () => {
    if (!audioInfo?.oss_key) {
      return message.warning('请上传音频')
    }
    completeCreate()
  }

  const onChange = (index: number, text: string) => {
    const updateSegments = [...segments]
    updateSegments[index].words = text
    if (!text?.replace(/<[^>]*>/g, '')) {
      updateSegments[index].defaultText = ''
    }
    setSegments(updateSegments)
  }

  const previewWords = async (index: number, words: string) => {
    return await previewTts(segments[index].voice, words)
  }

  const previewTts = async (tts: any, text: string) => {
    if (playingId === tts?.name) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.name)
    setPlayingId(undefined)
    clearAudio()

    try {
      let res
      if (tts.parent_id) {
        res = await homeApi.previewCommunityVoice(tts.parent_id, tts.id, {
          text,
          voice_parameters: tts.voice_parameters
        })
      } else {
        res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      }
      if (prviewRef.current !== tts.name) {
        return
      }
      setPlayingId(tts.name)

      clearAudio()

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const clearAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const closeAudio = () => {
    clearAudio()
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const toVideo = () => {
    location.href = '/video'
  }

  const selectDemoAudio = (v: any) => {
    setAudioInfo({
      oss_key: v.ossKey
    })
    setAudioUrl(urlSource(v.url))
    setCurrentStep(2)
  }

  const openPlan = () => {
    setPlanModalOpen(true)
  }

  const addVoiceSegment = (file: RcFile) => {
    const lastSegment = segments?.at(-1)
    setSegmentIndex(segments.length)
    const newSegment = [
      ...(segments || []),
      {
        voice: lastSegment.voice,
        avatar: lastSegment.avatar,
        audioFile: URL.createObjectURL(file),
        type: 1,
        id: uuid.v4()
      }
    ]
    setSegments(newSegment)
    return newSegment
  }

  const addTextSegment = () => {
    const lastSegment = segments?.at(-1)
    setSegments([
      ...(segments || []),
      {
        ...lastSegment,
        type: 2,
        id: uuid.v4(),
        words: '',
        defaultText: '',
        duration: 0
      }
    ])
  }

  const onVoiceChange = (voice: any) => {
    const updateSegments = [...segments]
    updateSegments[segmentIndex].voice = voice
    setSegments(updateSegments)
  }

  const updateVoiceSetting = (params: any) => {
    const updateSegments = [...segments]
    updateSegments[segmentIndex].voice.voice_parameters = params
    setSegments(updateSegments)
  }

  const onAvatarChange = (avatar: any) => {
    if (model === 'text') {
      const updateSegments = [...segments]
      updateSegments[segmentIndex].avatar = avatar
      setSegments(updateSegments)
    } else {
      setAudioSelectAvatar(avatar)
    }
  }

  const deleteSegment = (index: number) => {
    setSegments(segments.filter((_, i) => index !== i))
  }

  return (
    <div className="page-create page-create-video">
      <div className="page-header">
        <div className="title">
          <span className="icon" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
          </span>
          <label>创建视频</label>
        </div>
        <div className="right">
          {edit ? (
            <Input
              autoFocus
              maxLength={20}
              value={textInputName}
              onChange={(e) => setTextInputName(e.target.value)}
              onBlur={saveName}
              onPressEnter={saveName}
            ></Input>
          ) : (
            <label className="name">
              {textInputName}
              <FormOutlined onClick={() => setEdit(true)} />
            </label>
          )}
        </div>
      </div>

      <div className="create-content">
        <div className="left">
          <div className="tabs">
            <Radio.Group
              value={model}
              onChange={(e) => {
                CreateVideoStore.model = e.target.value
              }}
              buttonStyle="solid"
            >
              <Radio.Button value="text">文本驱动</Radio.Button>
              <Radio.Button value="audio">音频驱动</Radio.Button>
            </Radio.Group>
            {!!segments.length && model === 'text' && (
              <div className="stat">
                <span>
                  <Popover
                    content={
                      <div className="common-popover">
                        <div>
                          预估时长是我们根据系统声音合成速率估算得出，与实际作品时长存在误差，
                          <span className="red">积分扣除以最终实际合成时长为准</span>。
                        </div>
                        <ul>
                          <li>
                            积分模式：单个作品最大时长限制为<span className="red">3600秒</span>（1小时、约1.5万字）
                          </li>
                          <li>
                            黑金模式：单个作品最大时长限制为<span className="red">180秒</span>（3分钟、约750字）
                          </li>
                        </ul>
                      </div>
                    }
                    placement="right"
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                  预估总时长：
                </span>
                {timeThanLimit ? (
                  <span className="time red">{showTime}</span>
                ) : (
                  <span className="time">{showTime}</span>
                )}
              </div>
            )}
          </div>

          <div className="step-content" style={{ display: model === 'text' ? 'flex' : 'none' }}>
            {segments?.map((s: any, index: number) => (
              <div className="content-box" key={s.id}>
                <div className="photo">
                  <img
                    src={
                      s.avatar?.source_type === 3 || (s.avatar?.source_type === 2 && s.avatar?.status !== 2)
                        ? s.avatar?.video_url
                        : urlSource(s.avatar?.video_url, 'video')
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentAvatar(s.avatar)
                      setSegmentIndex(index)
                      setAvatarModalOpen(true)
                    }}
                  >
                    更换
                  </Button>
                </div>

                {s.type !== 1 ? (
                  <>
                    <RichText
                      defaultText={s.defaultText || ''}
                      onChange={onChange.bind(this, index)}
                      onPreviewWords={previewWords.bind(this, index)}
                    />

                    <div className="choose-voice">
                      <div className="choose-voice__left">
                        <div className="left-img">
                          <img src={s.voice?.group_cover_url} />
                        </div>
                        <div
                          className="play-pause"
                          onClick={(e) => {
                            e.stopPropagation()
                            previewTts(s.voice, s.voice?.preview_text || defaultPreviewText)
                          }}
                        >
                          {previewId === s.voice?.name ? (
                            playingId === s.voice?.name ? (
                              <PauseOutlined />
                            ) : (
                              <LoadingOutlined />
                            )
                          ) : (
                            <PlayGray className="play-icon" />
                          )}
                        </div>
                        <div className="detail">
                          <div className="name ellipsis">{s.voice?.group_title}</div>
                          <div className="style ellipsis">{s.voice?.display_name}</div>
                        </div>
                      </div>
                      <div className="choose-voice__right">
                        <Button
                          type="primary"
                          className="black"
                          onClick={() => {
                            setSegmentIndex(index)
                            setVoiceModalOpen(true)
                            setCurrentTts(s.voice)
                          }}
                        >
                          更换声音
                        </Button>
                        {s.voice?.level === 20 && <Button onClick={() => setSettingPreview(s.voice)}>调整参数</Button>}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="segments-voice">
                    <audio controls src={s.audioFile} />
                    {!s.audio_oss_key && (
                      <div className="segment-mask">
                        <LoadingOutlined />
                        <div>上传中 {s.percent}%</div>
                      </div>
                    )}
                  </div>
                )}

                {segments.length > 1 && (
                  <div className="segment-trash" onClick={deleteSegment.bind(this, index)}>
                    <DeleteOutlined />
                  </div>
                )}
              </div>
            ))}

            {!!segments.length && open && (
              <div className="segments-add">
                <Upload
                  accept=".mp3,.m4a,.wav"
                  showUploadList={false}
                  beforeUpload={async (file) => {
                    const duration = (await beforeAudioUpload(file)) as any
                    if (duration) {
                      const newSegment = addVoiceSegment(file)
                      const id = newSegment.at(-1)?.id
                      uploadFile(
                        file,
                        (percent) => {
                          const index = segmentsCurrent.current.findIndex((s: any) => s.id === id)
                          const updateSegments = [...segmentsCurrent.current]
                          updateSegments[index].percent = percent
                          setSegments(updateSegments)
                        },
                        (audio_oss_key) => {
                          const index = segmentsCurrent.current.findIndex((s: any) => s.id === id)
                          const updateSegments = [...segmentsCurrent.current]
                          updateSegments[index].duration = duration
                          updateSegments[index].percent = 100
                          updateSegments[index].audio_oss_key = audio_oss_key
                          setSegments(updateSegments)
                        }
                      )
                    }
                    return !!duration
                  }}
                >
                  <Button icon={<PlusOutlined />} color="primary" variant="outlined">
                    添加音频
                  </Button>
                </Upload>

                <Button icon={<PlusOutlined />} color="primary" variant="outlined" onClick={addTextSegment}>
                  添加文本
                  <div className="tag">new</div>
                </Button>
              </div>
            )}

            <div ref={lastRef}></div>
          </div>

          <div className="step-content audio" style={{ display: model !== 'text' ? 'flex' : 'none' }}>
            <div className="content-box">
              <div className="photo">
                <img
                  src={
                    audioSelectAvatar?.source_type === 3 ||
                    (audioSelectAvatar?.source_type === 2 && audioSelectAvatar?.status !== 2)
                      ? audioSelectAvatar?.video_url
                      : urlSource(audioSelectAvatar?.video_url, 'video')
                  }
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrentAvatar(audioSelectAvatar)
                    setAvatarModalOpen(true)
                  }}
                >
                  更换
                </Button>
              </div>
              <div className="audio-right">
                <div className="warning">
                  <h4>音频要求</h4>
                  <div className="desc">
                    <div>
                      <label className="label">文件格式：</label>
                      <label>mp3、m4a、wav</label>
                    </div>
                    <div>
                      <label className="label">音频时长：</label>
                      <label>5秒～30分钟</label>
                    </div>
                  </div>
                </div>

                <div className="content">
                  {currentStep === 0 && (
                    <Dragger
                      accept=".mp3,.m4a,.wav"
                      showUploadList={false}
                      beforeUpload={async (file) => {
                        const flag = (await beforeAudioUpload(file)) as any
                        if (flag) {
                          setAudioFile(file)
                          uploadFile(
                            file,
                            (percent) => {
                              if (percent === 0) {
                                setCurrentStep(1)
                              }
                              setPercent(percent)
                            },
                            (oss_key) => {
                              setCurrentStep(2)
                              setAudioInfo({ oss_key })
                            }
                          )
                        }
                        return flag
                      }}
                      onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                    >
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                      </p>
                      <p className="ant-upload-text">上传音频, 驱动生成视频</p>
                      <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                    </Dragger>
                  )}

                  {currentStep === 1 && (
                    <div className="step-progress">
                      <div className="step-progress-content">
                        <div className="percent">{percent}%</div>
                        <Progress percent={percent} showInfo={false} />
                        <div className="tips">音频上传中</div>
                      </div>
                      <div className="btns">
                        <Button onClick={restartUpload}>取消</Button>
                      </div>
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div className="step-view">
                      <div className="step-view-box">
                        <audio controls src={audioUrl} />
                        <div className="trash" onClick={restartUpload}>
                          <DeleteOutlined />
                        </div>
                      </div>
                      <div className="btns" onClick={restartUpload}>
                        <div>重新上传</div>
                      </div>
                    </div>
                  )}
                </div>
                {currentStep === 0 && <AudioDemos onSelect={selectDemoAudio} />}
              </div>
            </div>
          </div>

          <div className="footer-btn">
            {highSetting.mode === 1 ? (
              <Button className="btn-vip" loading={loading} type="primary" onClick={submitForm}>
                提交（不消耗积分）
              </Button>
            ) : (
              <Button loading={loading} type="primary" onClick={submitForm}>
                提交
              </Button>
            )}
          </div>
        </div>

        <div className="right">
          <CreateVideoSetting openPlan={openPlan} />
        </div>
      </div>

      <VoiceSettingModal
        preview={settingPreview}
        onCancel={() => setSettingPreview(undefined as any)}
        onOk={(voice_parameters: any) => {
          setSettingPreview(undefined as any)
          updateVoiceSetting(voice_parameters)
        }}
      />

      <VoiceModal
        open={voiceModalOpen}
        selectTts={currentTts}
        onCancel={() => setVoiceModalOpen(false)}
        upgradePlan={() => setPlanModalOpen(true)}
        onSelect={onVoiceChange}
      />

      <AvatarModal
        selectAvatar={currentAvatar}
        open={avatarModalOpen}
        onCancel={() => setAvatarModalOpen(false)}
        onSelect={onAvatarChange}
      />

      <PayPointModal
        open={payPointModalOpen}
        onCancel={() => setPayPointModalOpen(false)}
        onSuccess={() => UserStore.updateUserPackage()}
      />

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() => UserStore.updateUserPackage()}
      />
    </div>
  )
}

export default CreateVideo
