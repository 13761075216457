import resso from 'resso'

export const CreateVideoStore = resso({
  model: 'text',
  highSetting: {
    drive_mode: 0,
    only_generate_audio: false,
    mode: -1, // 0-普通模式 1-暗黑模式
    model_version: 0 // 0-1.0 1-1.5
  }
})
